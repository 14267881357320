import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CoinIcon } from '../../../../components/CoinIcon';
import { MButton } from '../../../../horizon-components/MButton';
import { BidStatusEnum } from '../../../../models/Auction';
import { useUserContext } from '../../../../providers';
import { AuctionViewAbout } from './auction-view-about';
import { AuctionViewBids } from './auction-view-bids';
import { AuctionViewRate } from './auction-view-rate';
import { AuctionViewRules } from './auction-view-rules';
import { sendBid } from '../../../../hooks/useAnalytics';
import { formatNumber } from '../../../../shared/utils/formatNumber';
import { useAuctionContext } from '../../../../providers/AuctionProvider/AuctionProvider';
import { useWalletConnection } from '../../../../hooks/useWalletConnection';

export const AuctionViewContent = () => {
  const { t, i18n } = useTranslation(['translation']);
  const { user } = useUserContext();
  const { bids, auction, setActualBid, setIsSendBidMode, auctionStatus } =
    useAuctionContext();
  const [amount, setAmount] = useState(0);
  const { handleOpenWalletModal } = useWalletConnection();

  useEffect(() => {
    const myBid = bids?.findIndex((bid) => bid.user.guid === user?.guid);
    const lastBid =
      user?.guid && auction?.number_winners
        ? myBid !== -1 && myBid < auction?.number_winners
          ? myBid + 1
          : auction?.number_winners
        : auction?.number_winners;

    const bidsRes = bids?.slice(0, lastBid) || [];
    const userBidPos = bidsRes.findIndex((bid) => {
      return bid.user.guid === user?.guid;
    });
    const win_pos =
      typeof userBidPos !== 'undefined' && userBidPos > -1
        ? userBidPos
        : (lastBid || 1) - 1;
    const winners = lastBid || 1;
    let recomBid = 1;
    if (bidsRes.length >= winners) {
      recomBid = Number(bidsRes[win_pos]?.bid_amount) + 1;
    }
    if (userBidPos === 0) {
      recomBid = Number(bidsRes[userBidPos]?.bid_amount) + 1;
    }
    const endPos = userBidPos === -1 ? bidsRes.length : userBidPos;
    const reBidsRes = bidsRes.reverse();
    for (let i = 0; i < endPos; i++) {
      if (recomBid === Number(reBidsRes[i]?.bid_amount)) {
        recomBid += 1;
      }
    }
    setAmount(recomBid);
  }, [auction, bids, setActualBid, user?.guid]);

  const handleBid = () => {
    setIsSendBidMode(true);
    setActualBid(amount);
    sendBid();
  };

  return (
    <div className={'flex w-full flex-col gap-3.5'}>
      <h1 className={'py-2 text-lg font-semibold text-gray-900 lg:text-2xl'}>
        {auction?.title}
      </h1>

      {!!auction && auctionStatus === BidStatusEnum.COMPLETED && (
        <div className={'rounded-full bg-secondaryGrey-300 p-4'}>
          <p className={'text-center text-2xl font-semibold text-gray-800'}>
            {t('bid.finished')}
          </p>
        </div>
      )}

      {auctionStatus === BidStatusEnum.ACTIVE &&
        auction?.only_wallet_owner === true &&
        user?.crypto_wallet.address === undefined && (
          <>
            <MButton
              variant={'highlighted'}
              color={'primary'}
              size={'sm'}
              className={
                'bottom-16-safe z-10 mb-[calc(env(safe-area-inset-bottom)+1rem)] !h-12 !border-primary-purple-blue-100 !bg-primary-purple-blue-100 !text-primary-purple-blue-500 lg-max:fixed lg-max:w-[calc(100vw_-_16px)]'
              }
              onClick={handleOpenWalletModal}
            >
              {t('buttons.connect_your_wallet', {
                bid: formatNumber(amount.toString(), i18n.language),
              })}
            </MButton>
            <AuctionViewRate />
          </>
        )}
      {auctionStatus === BidStatusEnum.ACTIVE &&
        ((auction?.only_wallet_owner === false &&
          user?.crypto_wallet.address === undefined) ||
          user?.crypto_wallet.address !== undefined) && (
          <>
            <MButton
              variant={'highlighted'}
              color={'primary'}
              size={'sm'}
              className={
                'bottom-16-safe z-10 mb-[calc(env(safe-area-inset-bottom)+1rem)] !h-12 lg-max:fixed lg-max:w-[calc(100vw_-_16px)]'
              }
              onClick={handleBid}
            >
              {t('bid.rate', {
                bid: formatNumber(amount.toString(), i18n.language),
              })}
              <CoinIcon />
            </MButton>
            <AuctionViewRate />
          </>
        )}

      {auctionStatus !== BidStatusEnum.PLANNED && <AuctionViewBids />}
      <AuctionViewAbout />
      <AuctionViewRules />
    </div>
  );
};
