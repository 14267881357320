import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Notification } from '../../../assets/icons/notification.svg';
import { ReactComponent as Lock } from '../../../assets/icons/lock.svg';
import { Card } from '../../../horizon-components/Card/Card';
import { Link } from '../../../horizon-components/Link';
import {
  AuctionRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { Auction, BidStatusEnum } from '../../../models/Auction';
import { CoinIcon } from '../../../components/CoinIcon';
import { BidTime } from '../../../widgets/bid-time';
import { BidChip } from '../../../widgets/BidChip';
import { formatNumber } from '../../../shared/utils/formatNumber';
import { useBidServiceContext } from '../../../providers/BidServiceProvider/BidServiceProvider';
import { useUserContext } from '../../../providers';

type Props = {
  data: Auction;
  status: BidStatusEnum;
  onZeroTimer?: () => void;
};
export const AuctionCard: React.FC<Props> = ({ data, onZeroTimer, status }) => {
  const { t, i18n } = useTranslation(['translation']);
  const { getMyBidForAuction, bidsCommonInfo } = useBidServiceContext();
  const { user } = useUserContext();

  const myBid = data?.id ? getMyBidForAuction(data?.id) : null;
  const auctionBidsCommonInfo = bidsCommonInfo?.[data.id.toString()];
  const highestBid = auctionBidsCommonInfo?.highest_bid || '0';
  const biddersCount = auctionBidsCommonInfo?.bidders_count || 0;
  const bidChipVariant = user?.crypto_wallet.address ? 'green' : 'primary';
  const IconComponent = user?.crypto_wallet.address ? Notification : Lock;

  return (
    <Link
      to={MainRoutes.bid + AuctionRoutes.auctionView + `/${data.id}`}
      className="contents"
    >
      <Card
        extra={`rounded-[24px] w-full !shadow-[14px_17px_40px_4px_#7090B014] p-[0px] overflow-hidden cursor-pointer`}
      >
        <div className={'relative overflow-hidden'}>
          <div className="absolute flex flex-col gap-2 p-3">
            {data.only_wallet_owner && (
              <BidChip variant="primary">{t('bid.wallet_required')}</BidChip>
            )}
            <BidChip variant={bidChipVariant}>
              <IconComponent className="!h-3 !w-3 !flex-none fill-primary-green-600" />
              <BidTime data={data} onZeroTimer={onZeroTimer} status={status} />
            </BidChip>
            <BidChip
              variant={'secondary'}
            >{`${data.number_winners} ${t('bid.winners_count')}`}</BidChip>
          </div>
          <img
            src={
              data.images?.[0]?.image ||
              require('../../../assets/images/banner-placeholder.webp')
            }
            className={'h-[176px] w-full object-cover'}
            alt={'auction'}
          />
        </div>
        <div className={'px-[12px] pb-[16px] pt-[8px]'}>
          <p className={'text-[16px] font-[600] text-gray-900'}>{data.title}</p>
          <div className={'flex justify-between'}>
            <div className={'flex w-full justify-between'}>
              <div>
                <p className={'text-[10px] font-medium text-gray-600'}>
                  {t('bid.highestBid')}
                </p>
                <div className={'flex items-center gap-[4px]'}>
                  <CoinIcon />
                  <span className={'text-sm font-medium text-gray-900'}>
                    {formatNumber(highestBid, i18n.language)}
                  </span>
                </div>
              </div>
              <div>
                <p className={'text-[10px] font-medium text-gray-600'}>
                  {t('bid.myBid')}
                </p>
                <div className={'flex items-center gap-[4px]'}>
                  <CoinIcon />
                  <span className={'text-sm font-medium text-gray-900'}>
                    {myBid?.bid_amount
                      ? formatNumber(myBid?.bid_amount.toString(), i18n.language)
                      : 0}
                  </span>
                </div>
              </div>
              <div>
                <p className={'text-[10px] font-medium text-gray-600'}>
                  {t('bid.bidders')}
                </p>
                <div className={'flex items-center gap-[4px]'}>
                  <span className={'text-sm font-medium text-gray-900'}>
                    {biddersCount}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </Link>
  );
};
