import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getIsRewardClaimed } from '../../../../api/bid';
import { Loader } from '../../../../components/Loader';
import { BottomSheet } from '../../../../horizon-components/bottom-sheet/ui';
import { Modal } from '../../../../horizon-components/Modal';
import { BidStatusEnum } from '../../../../models/Auction';
import { useAuctionContext } from '../../../../providers/AuctionProvider/AuctionProvider';
import { useBidServiceContext } from '../../../../providers/BidServiceProvider/BidServiceProvider';
import { AuctionViewBreadcrumbs } from './auction-view-breadcrumbs';
import { AuctionViewContent } from './auction-view-content';
import { AuctionViewPoster } from './auction-view-poster';
import { AuctionViewWinner } from './auction-view-winner';
import { AuctionViewWinnerForm } from './auction-view-winner-form';

type Props = {
  auctionId: number;
};

export const AuctionView: React.FC<Props> = ({ auctionId }) => {
  const { getMyBidForAuction, isMyBidsLoading, fetchBidsCommonInfo } =
    useBidServiceContext();
  const { t } = useTranslation(['translation']);
  const pollingTimeout = useRef<null | NodeJS.Timer>(null);

  const {
    auction,
    getIsWinner,
    isAuctionLoading,
    bids,
    auctionStatus,
    isBidsLoading,
    fetchBids,
  } = useAuctionContext();
  const [winnerFormModalIsOpen, setWinnerFormModalIsOpen] = useState(false);
  const [winnersListModalIsOpen, setWinnersListModalIsOpen] = useState(false);
  const [isAnyModalShowed, setIsAnyModalShowed] = useState(false);

  const winners = bids?.filter((item) => getIsWinner(item)) || [];
  const isMultiWinners = winners.length > 1;

  useEffect(() => {
    if (!isMyBidsLoading && !isBidsLoading && auction && !isAnyModalShowed) {
      const myBid = getMyBidForAuction(auctionId);
      const isWinner = getIsWinner(myBid);
      if (isWinner) {
        getIsRewardClaimed(auctionId).then((isRewardClaimed) => {
          if (!isRewardClaimed) {
            setIsAnyModalShowed(true);
            setWinnerFormModalIsOpen(true);
            setWinnersListModalIsOpen(false);
          } else if (winners.length >= 1) {
            setIsAnyModalShowed(true);
            setWinnersListModalIsOpen(true);
            setWinnerFormModalIsOpen(false);
          }
        });
      } else if (auctionStatus === BidStatusEnum.COMPLETED && winners.length >= 1) {
        setIsAnyModalShowed(true);
        setWinnersListModalIsOpen(true);
        setWinnerFormModalIsOpen(false);
      }
    }
  }, [
    auctionId,
    getMyBidForAuction,
    getIsWinner,
    setWinnerFormModalIsOpen,
    auctionStatus,
    setWinnersListModalIsOpen,
    isMyBidsLoading,
    isBidsLoading,
    auction,
    winners.length,
    isAnyModalShowed,
  ]);

  useEffect(() => {
    let isMounted = true;

    const poll = async () => {
      if (!isMounted) return;

      try {
        await fetchBids();
      } catch (error) {
        console.error('Error fetching bids:', error);
      }

      if (isMounted) {
        pollingTimeout.current = setTimeout(poll, 5000);
      }
    };

    if (auctionStatus === BidStatusEnum.ACTIVE && !pollingTimeout.current) {
      poll();
    } else if (auctionStatus === BidStatusEnum.COMPLETED) {
      fetchBids();
    } else if (auctionStatus !== BidStatusEnum.ACTIVE && pollingTimeout.current) {
      clearTimeout(pollingTimeout.current);
      pollingTimeout.current = null;
    }

    return () => {
      isMounted = false;
      if (pollingTimeout.current) {
        clearTimeout(pollingTimeout.current);
        pollingTimeout.current = null;
      }
    };
  }, [fetchBids, auctionStatus]);

  useEffect(() => {
    fetchBidsCommonInfo();
  }, []);

  return (
    <div>
      <div className="container mb-2.5 hidden md:block">
        <AuctionViewBreadcrumbs />
      </div>
      {isAuctionLoading ? (
        <Loader />
      ) : (
        <div className={'grid grid-cols-1 gap-4 pb-4 lg:grid-cols-2 lg:gap-3.5'}>
          <AuctionViewPoster />
          <AuctionViewContent />

          {auction ? (
            window.innerWidth > 768 ? (
              <Modal
                modalId={'winner-form-modal'}
                isOpen={winnerFormModalIsOpen}
                onClose={() => setWinnerFormModalIsOpen(false)}
                showClose={false}
                maxWidth={540}
              >
                <AuctionViewWinnerForm
                  auctionId={auctionId}
                  auction={auction}
                  onClose={() => setWinnerFormModalIsOpen(false)}
                />
              </Modal>
            ) : (
              <BottomSheet
                modalId={'winner-form-modal'}
                open={winnerFormModalIsOpen}
                onDismiss={() => setWinnerFormModalIsOpen(false)}
              >
                <AuctionViewWinnerForm
                  auctionId={auctionId}
                  auction={auction}
                  onClose={() => setWinnerFormModalIsOpen(false)}
                />
              </BottomSheet>
            )
          ) : null}
          {auction ? (
            window.innerWidth > 768 ? (
              <Modal
                title={t('bid.Our_winners')}
                modalId={'winned-modal'}
                isOpen={winnersListModalIsOpen}
                onClose={() => setWinnersListModalIsOpen(false)}
                maxWidth={410}
              >
                <AuctionViewWinner
                  isMultiWinners={isMultiWinners}
                  winners={winners}
                  auction={auction}
                  onClose={() => setWinnersListModalIsOpen(false)}
                />
              </Modal>
            ) : (
              <BottomSheet
                modalId={'winned-modal'}
                open={winnersListModalIsOpen}
                onDismiss={() => setWinnersListModalIsOpen(false)}
              >
                <AuctionViewWinner
                  isMultiWinners={isMultiWinners}
                  winners={winners}
                  auction={auction}
                  onClose={() => setWinnersListModalIsOpen(false)}
                />
              </BottomSheet>
            )
          ) : null}
        </div>
      )}
    </div>
  );
};
