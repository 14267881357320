import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as NotifyIcon } from '../../assets/icons/menu/notify.svg';
import { ReactComponent as ERIcon } from '../../assets/icons/new-er.svg';
import { ChangeLanguage } from '../../features/change-language';
import { Notifications } from '../../features/Notifications';
import useClickOutside from '../../hooks/useClickOutside';
import { BottomSheet } from '../../horizon-components/bottom-sheet/ui';
import { Modal } from '../../horizon-components/Modal';
import { MTooltip } from '../../horizon-components/MTooltip/MTooltip';
import { useUserContext } from '../../providers';
import { useModal } from '../../providers/ModalProvider';
import { useNotificationsWebsocket } from '../../providers/NotificationsWebsocketProvider';
import { formatNumber } from '../../shared/utils/formatNumber';
import { getUserData } from '../../utils/getUserData';
import { DetailTransactionModal } from '../../widgets/DetailTransactionModal';
import { ProfileDropdown } from './ProfileDropdown';
import { AuthRoutes, MainRoutes, ProfileRoutes } from './Routes/types/routes';

type Props = {
  isMapPage: boolean;
};

const NavbarMenu: React.FC<Props> = ({ isMapPage }) => {
  const { i18n, t } = useTranslation(['translation']);
  const { lastReceivedNotification } = useNotificationsWebsocket();
  const [isNewNotify, setIsNewNotify] = useState<boolean>(false);
  const [open, setRef, toggleOpen, setActivatorRef] = useClickOutside(false);
  const { wallet, user } = useUserContext();
  const { openModal, closeModal } = useModal();
  const { image } = getUserData(user || undefined);
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(
      MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.user + `/${user?.guid}`
    );
  };

  useEffect(() => {
    dayjs.locale(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (lastReceivedNotification) {
      setIsNewNotify(true);
    }
  }, [lastReceivedNotification]);

  const handlNotify = () => {
    toggleOpen();
    if (!open) {
      setIsNewNotify(false);
    }
  };

  return (
    <div
      className={`profile relative flex items-center justify-around gap-1 rounded-full bg-white 
      ${isMapPage ? 'p-0 lg:p-1' : 'p-1'} 
      text-sm font-bold text-secondary-grey-900 shadow-sm shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none lg:gap-4 lg:px-4 lg:py-3`}
    >
      <ChangeLanguage />

      <div className="flex items-center justify-around gap-1 lg:gap-4">
        <div
          className={
            'relative flex h-[34px] w-[34px] cursor-pointer items-center justify-center gap-1 self-stretch rounded-2xl lg-max:bg-primary-purple-blue-100 lg-max:p-2'
          }
          id={'notify'}
        >
          {isNewNotify && (
            <div className="absolute right-0.5 top-1 h-[5px] w-[5px] rounded-full bg-primary-purple-blue-500 outline outline-1 outline-white lg:top-2" />
          )}
          <div ref={setActivatorRef}>
            <NotifyIcon
              onClick={handlNotify}
              className="fill-primary-purple-blue-500 lg:fill-primary-purple-blue-200"
            />
          </div>
          <MTooltip anchorSelect={'#notify'} text={t('notifications.title')} />
        </div>
        {open && <Notifications onClose={handlNotify} ref={setRef} />}

        <div
          className={
            'flex cursor-pointer items-center justify-center gap-1 self-stretch rounded-2xl bg-primary-purple-blue-100 px-2.5 py-2 pl-4 sm:relative'
          }
          onClick={() => openModal('details')}
          id={'tokins'}
        >
          <span className="text-xs font-semibold tabular-nums text-primary-purple-blue-500">
            {formatNumber(wallet?.value || '0', i18n.language)}
          </span>
          <ERIcon className="h-[18px] w-[18px] fill-primary-purple-blue-500" />
        </div>

        <div onClick={handleNavigate} className="lg:hidden">
          <img
            className="h-[34px] w-[34px] min-w-[34px] max-w-[34px] rounded-full object-cover"
            src={image}
            id={'profile'}
            alt="profile"
          />
        </div>
      </div>

      <div className="lg-max:!hidden">
        <ProfileDropdown />
      </div>
      {window.innerWidth > 768 ? (
        <Modal
          title={t('buttons.Details')}
          onClose={() => closeModal('details')}
          modalId="details"
        >
          <DetailTransactionModal />
        </Modal>
      ) : (
        <BottomSheet
          modalId="details"
          onDismiss={() => closeModal('details')}
          noScroll
        >
          <DetailTransactionModal />
        </BottomSheet>
      )}
    </div>
  );
};

export default NavbarMenu;
