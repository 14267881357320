import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../providers';
import { MButton } from '../../horizon-components/MButton';
import { useWalletConnection } from '../../hooks/useWalletConnection';

interface TonWalletCardProps {
  className?: string;
}

export const TonWalletCard = ({ className }: TonWalletCardProps) => {
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();
  const { handleOpenWalletModal } = useWalletConnection();

  const tonWalletAddress = user?.crypto_wallet?.address
    ? `${user?.crypto_wallet.address.slice(0, 4)}...${user?.crypto_wallet.address.slice(-4)}`
    : '';

  return (
    <div className={className}>
      {tonWalletAddress && (
        <p className="mb-5 text-lg font-bold text-secondary-grey-900 md:text-xl">
          TON Wallet: {tonWalletAddress}
        </p>
      )}
      <MButton
        color={'primary'}
        size={'sm'}
        variant={'highlighted'}
        onClick={handleOpenWalletModal}
        className={
          '!hover:border-[#007bb5 w-[180px] !rounded-full !border-[#0098ea] !bg-[#0098ea]'
        }
      >
        <p className="font-bold">
          {user?.crypto_wallet?.address === undefined
            ? t('buttons.connect_wallet')
            : t('buttons.change_wallet')}
        </p>
      </MButton>
    </div>
  );
};
